<template>
    <div class="login-container d-flex align-items-center">
        <div class="form-login">
        <form @submit.prevent="confirm()">
            <h3 class="text-black fw-bold mb-4">Change Password</h3>
            <div class="textBox-width">
                <span class="p-float-label">
                    <InputText
                        id="newpassword"
                        type="password"
                        class="p-inputtext w-100"
                        v-model="v$.newPassword.$model"
                    />
                    <label for="newpassword">New Password</label>
                </span>
                <span
                    v-if="v$.newPassword.$error && (v$.newPassword.passwordRequired && v$.newPassword.passwordRequired.$invalid)"
                    class="text-danger">{{messages.registrationValidation.passwordRequired}}
                </span>
                <span
                    v-else-if="v$.newPassword.$error &&
                        (v$.newPassword.passwordMustHaveFormat && v$.newPassword.passwordMustHaveFormat.$invalid)"
                    class="text-danger">{{messages.registrationValidation.passwordCondition}}
                </span>
            </div>
            <div class="textBox-width mt-4">
                <span class="p-float-label">
                    <InputText
                        id="confirmpassword"
                        type="password"
                        class="p-inputtext w-100"
                        v-model="v$.confirmPassword.$model"
                    />
                    <label for="confirmpassword">Confirm Password</label>
                </span>
                <span
                    v-if="v$.confirmPassword.$error && (v$.confirmPassword.sameAsPassword && v$.confirmPassword.sameAsPassword.$invalid)"
                    class="text-danger">Please re-enter password
                </span>
                <span v-if="errorMessage" class="text-danger">{{ errorMessage }}</span>
            </div>
            <Button
                class="lavender-purple-background justify-content-center textBox-width mt-4"
                type="submit"
            >
                <span class="size18 fw-normal">UPDATE</span>
            </Button>
            <Button class="p-button-outlined justify-content-center textBox-width mt-3" type="submit" @click.prevent="cancel">
                <span class="color-black size18 fw-normal">CANCEL</span>
            </Button>
        </form>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { sameAs } from '@vuelidate/validators';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { toasterTime, messages } from '../constants/constants';
import { AuthService } from '../../apis';
import { passwordMustHaveFormat, passwordRequired } from '../utils/validation-utils';

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'ChangePassword',
    components: {
        InputText,
        Button
    },
    data() {
        return {
            newPassword: '',
            confirmPassword: '',
            errorMessage: '',
            messages
        };
    },
    validations() {
        return {
            newPassword: { passwordRequired, passwordMustHaveFormat },
            confirmPassword: { sameAsPassword: sameAs(this.newPassword) }
        };
    },
    methods: {
        async confirm() {
            const isFormValid = await this.v$.$validate();
            if (!isFormValid) {
                return;
            }
            AuthService.passwordUpdate(this.newPassword)
                .then(() => AuthService.logout().then(() => this.$router.replace({ name: 'Login' })))
                .catch(err => {
                    console.log(err);
                    this.$toast.add({
                        severity: 'error',
                        closable: false,

                        detail: messages.changePasswordValidation.loginAgain,
                        life: toasterTime
                    });
                });
        },
        checkIfValid() {
            if (this.newPassword != this.confirmPassword) {
                this.errorMessage = messages.changePasswordValidation.passwordNotMatched;
            }
            return this.errorMessage == '';
        },
        removeErrorMessage() {
            this.errorMessage = '';
        },
        cancel() {
            this.$router.go(-1);
        }
    }
};
</script>
